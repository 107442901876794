const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
    // namesub: [
    //     {
    //         id: 1,
    //         sub: 'Home 01',
    //     },
    //     // {
    //     //     id: 2,
    //     //     sub: 'Home 02',
    //     //     links: '/home-02'
    //     // },
    // ]
  },
  // {
  //   id: 2,
  //   name: "About Us",
  //   links: "/about-us",
  //   // namesub: [
  //   //     {
  //   //         id: 1,
  //   //         sub: 'About Us',
  //   //     },
  //   //     {
  //   //         id: 2,
  //   //         sub: 'About Detail',
  //   //         links: '/about-detail'
  //   //     },
  //   //     {
  //   //         id: 3,
  //   //         sub: 'About Team',
  //   //         links: '/about-team'
  //   //     },
  //   //     {
  //   //         id: 4,
  //   //         sub: 'About Carees',
  //   //         links: '/about-carees'
  //   //     }
  //   // ],
  // },
  {
    id: 4,
    name: "Colonies",
    links: "/colonies",
    // namesub: [
    //     {
    //         id: 1,
    //         sub: 'Colonies',
    //     },
    //     {
    //         id: 2,
    //         sub: 'Colonies Full Width',
    //         links: '/project-full-width'
    //     },
    //     {
    //         id: 3,
    //         sub: 'Colonies Detail',
    //         links: '/project-detail'
    //     }
    // ],
  },
  // {
  //     id: 5,
  //     name: 'Page',
  //     namesub: [
  //         {
  //             id: 1,
  //             sub: 'Page Testimonial',
  //             links: '/page-testimonial'
  //         },
  //         {
  //             id: 2,
  //             sub: 'Page Pricing',
  //             links: '/page-pricing'
  //         }
  //     ],
  // },
  {
    id: 6,
    name: "Finance",
    links: "/Finance",
    // namesub: [
    //     {
    //         id: 1,
    //         sub: 'Finance',
    //     },
    //     {
    //         id: 2,
    //         sub: 'Finance Single',
    //         links: '/Finance-single'
    //     }
    // ],
  },
  // {
  //   id: 3,
  //   name: "Buy-Sell Properties",
  //   links: "/Buy-SellProperties",
  // },
  {
    id: 7,
    name: "Contact",
    links: "/contact-02",
    // namesub: [
    //     {
    //         id: 1,
    //         sub: 'Contact 1',
    //     },
    //     {
    //         id: 2,
    //         sub: 'Contact 2',
    //         links: '/contact-02'
    //     }
    // ],
  },
];

export default menus;
