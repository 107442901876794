const bannerhome1 = [
    {
        id: 1,
        srcimg: 'assets/img/slider/slider-bg-4.jpg',
        classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600',
        classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700',
        datax: "['left','left','left','center']",
        datahset: "['0','0','0','0']",
        datavset1: "['-90','-90','-90','-90']",
        datavset2: "['-20','-20','-20','-20']",
        datavset3: "['45','45','45','45']",
        datavset: "['153','153','153','153']",
        classbtn: "tp-caption"
    },
    {
        id: 2,
        srcimg: 'assets/img/slider/slider-bg-3.jpg',
        classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-center',
        classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-center',
        datax: "['center','center','center','center']",
        datahset: "['0','0','0','0']",
        datavset1: "['-90','-90','-90','-90']",
        datavset2: "['-20','-20','-20','-20']",
        datavset3: "['45','45','45','45']",
        datavset: "['153','153','153','153']",
        classbtn: "tp-caption text-center"
    },
    {
        id: 3,
        srcimg: 'assets/img/slider/slider-bg-4.jpg',
        classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
        classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
        datax: "['right','right','right','center']",
        datahset: "['0','0','0','0']",
        datavset1: "['-90','-90','-90','-90']",
        datavset2: "['-20','-20','-20','-20']",
        datavset3: "['45','45','45','45']",
        datavset: "['153','153','153','153']",
        classbtn: "tp-caption text-right"
    }
]

export default bannerhome1;