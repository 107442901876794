import React, { Component } from 'react';
import BannerTitle from '../layouts/about/banner/BannerTitle'
import {Header, Footer, TopBar, BottomBar} from '../layouts/general/index'
import { ContentProject, RelateProject, Sidebar } from '../layouts/project';
import { withRouter } from 'react-router-dom';
import FormContact from './../layouts/contact/FormContact';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";

toast.configure();
class ProjectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          logoweb: "assets/img/logo-small.png",
          names: "Projects",
        },
      ],
      banners: [
        {
          id: 1,
          links: "/colonies",
          titlelink: "Colonies ",
          border: "|",
          name: "Colony name",
          heading: "Colony name",
        },
      ],
      colony:undefined,
      loading:true
    };
  }
  componentDidMount() {
    this.setState((prev)=>{
      return {...prev,loading:true}
    })
    fetch("https://rajeshreebackend.pythonanywhere.com/app1/detail/colony/")
      .then((res) => res.json())
      .then((data) => {
      
        this.setState((curstate) => {
             const ID = this.props.match.params.colonyid;
             let colonyh=data.filter((a)=>{return a.id==ID});
             if(colonyh.length===0){
               window.location.href="/colonies";
               return ;
             }
             else
             colonyh=colonyh[0];
          return {
            ...curstate,
            colony: colonyh,
            loading: false,
            banners: [
              {
                id: 1,
                links: "/colonies",
                titlelink: "Colonies ",
                border: "|",
                name: colonyh.Name,
                heading: colonyh.Name,
              },
            ],
          };
        });
      })
      .catch((err) => {
        this.setState((prev) => {
          return { ...prev, loading: false };
        });
          toast.error("Error while Loading Data");
      });
  }
  render() {
      
    return (
      <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
        <div id="wrapper" className="animsition">
          <div id="page" className="clearfix">
            <div id="site-header-wrap">
              <TopBar />
              {this.state.headers.map((data, idx) => (
                <Header data={data} key={idx} />
              ))}
            </div>
            {this.state.banners.map((data) => (
              <BannerTitle key={data.id} data={data} />
            ))}
            <div id="main-content" className="site-main clearfix">
              <div id="content-wrap">
                <div id="site-content" className="site-content clearfix">
                  <div id="inner-content" className="inner-content-wrap">
                    <div className="page-content">
                      <div className="row-project-detail">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="themesflat-spacer clearfix"
                                data-desktop={60}
                                data-mobile={60}
                                data-smobile={60}
                              />
                              <div className="detail-inner-wrap">
                                {this.state.loading && (
                                  <ReactLoading
                                    type="spin"
                                    color="yellow"
                                  ></ReactLoading>
                                )}
                                {this.state.colony !== undefined && (
                                  <Sidebar colony={this.state.colony} />
                                )}
                                {this.state.colony !== undefined && (
                                  <ContentProject colony={this.state.colony} />
                                )}
                              </div>
                              <div
                                style={{
                                  marginTop: "50px",
                                  color: "#ffc30c",
                                  alignSelf: "flex-start",
                                  fontSize: "large",
                                }}
                                className="content"
                              >
                                * Terms and conditions applied
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: "25px",
                                }}
                              >
                                <FormContact />
                              </div>
                              <div
                                className="themesflat-spacer clearfix"
                                data-desktop={58}
                                data-mobile={60}
                                data-smobile={60}
                              />
                            </div>
                          </div>
                          {/* <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-lines style-1 line-full line-solid clearfix"><span className="line" /></div>
                                                            <div className="themesflat-spacer clearfix" data-desktop={46} data-mobile={35} data-smobile={35} />
                                                            <div className="themesflat-headings style-2 clearfix">
                                                                <h2 className="heading">RELATED PROJECTS</h2>
                                                                <div className="sep has-width w80 accent-bg margin-top-3 clearfix" />
                                                            </div>
                                                            <div className="themesflat-spacer clearfix" data-desktop={35} data-mobile={35} data-smobile={35} />
                                                            <RelateProject />
                                                            <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                                                        </div>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            {this.state.headers.map((data, idx) => (
              <BottomBar data={data} key={idx} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProjectDetail);