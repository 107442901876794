import React, { Component } from "react";
import { Link } from "react-router-dom";

class TopBar extends Component {
  render() {
    return (
      <div id="top-bar">
        <div id="top-bar-inner" className="container">
          <div className="top-bar-inner-wrap">
            <div className="top-bar-content">
              <div className="inner">
                <span className="address content">
                  05 Balaji Nagar Main Road, Balgarh Main Road, Dewas
                </span>
                <span className="phone content">+919826072384</span>
                <span className="phone content">+919755710501</span>
                {/* <span className="time content">Mon-Sat: 8am - 6pm</span> */}
              </div>
            </div>
            <div className="top-bar-socials">
              <div className="inner">
                <span className="text">Follow us:</span>
                <span className="icons">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/AnandViharLakeView
"
                  >
                    <i className="fa fa-facebook" />
                  </a>
                  {/* <Link to="#">
                        <i className="fa fa-twitter" />
                      </Link> */}
                  <a
                    target="_blank"
                    href="https://www.instagram.com/rajeshreemarketing/"
                  >
                    <i className="fa fa-instagram" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
