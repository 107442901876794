import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
class TabProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects:
        this.props.filter === "ALL"
          ? this.props.colonies
          : this.props.colonies.filter((a) => {
              return a.Place.split(" ")[0] === this.props.filter;
            }),
    };
  }
  //  {
  //                     id: 6,
  //                     classnames: 'project-item Indore',
  //                     srcimg: 'assets/img/project/project-6-370x245.jpg',
  //                     heading: 'GRAM HOTEL MAMZANA, MEWYORK',
  //                     name01: 'Indore',

  //                 }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filter !== this.props.filter) {
      this.setState((prev)=>{
          return {
            ...prev,
            projects:
              this.props.filter === "ALL"
                ? this.props.colonies
                : this.props.colonies.filter((a) => {
                    return a.Place.split(',')[0].trim().toUpperCase() === this.props.filter;
                  }),
          };
      })
    }
  }
  render() {
    
    return (
      <div className="themesflat-project style-2 isotope-project has-margin mg15 data-effect clearfix">
        {this.state.projects.map((data, idx) => {
          let classname = "project-item" + ' '+data.Place.split(" ")[0];
          let url =
            "https://rajeshreebackend.pythonanywhere.com/" +
            data.colony_images[0].Image;
          return (
            <div className={classname} key={idx}>
              <div className="inner">
                <div className="thumb data-effect-item has-effect-icon w40 offset-v-19 offset-h-49">
                  <img src={url} alt="altimage" />
                  <div className="elm-link">
                    {/* <Link to="#" className="icon-1 icon-search" /> */}
                    <Link
                      to="#"
                      onClick={() => {
                        window.location.href = `/colonies/${data.id}`;
                      }}
                      className="icon-1"
                    />
                  </div>
                  <div className="overlay-effect bg-color-3" />
                </div>
                <div className="text-wrap" onClick={()=>{  window.location.href = `/colonies/${data.id}`;}}>
                  <h5 className="heading">
                    <Link
                      to="#"
                      onClick={() => {
                        window.location.href = `/colonies/${data.id}`;
                      }}
                    >
                      {data.Name}
                    </Link>
                  </h5>
                  <div className="elm-meta">
                    <span>
                      <Link
                        to="#"
                       
                      >
                        {data.Address}
                      </Link>
                    </span>
                    <span>
                      <Link
                        to="#"
                       
                      >
                        {data.Place}
                      </Link>
                    </span>
                    {/* <span>
                                    <Link to="#">{data.name01}</Link></span> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default withRouter(TabProject);
