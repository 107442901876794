import React, { Component } from 'react';
import axios from 'axios';

class FormContact02 extends Component {
    constructor() {
        super();
          this.state = {
            Name: "",
            Mobile:"",
            Email: "",
            Message:""
          };
      }
  
      handleSubmit = (e) => {
          e.preventDefault();
          let emailPattern = new RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);
          let emailPattern2 = new RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+\.[A-Za-z]+$/);
          let emailPattern3 = new RegExp(/^[a-zA-Z0-9]+\.+[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]/);
          if(this.state.Name && this.state.Email && this.state.Mobile && (emailPattern.test(this.state.Email) || emailPattern2.test(this.state.Email)|| emailPattern3.test(this.state.Email))){
            alert("Thank You");  
            axios.post("https://rajeshreebackend.pythonanywhere.com/app1/detail/adduser/",this.state)
              .then(res => {
                console.log("Done");   
              })
          }
      };
  
      onInputChangeHandlar = (event) => {
          const value = event.target.value;
          const name = event.target.name;
      
          this.setState({ [name]: value });
      };
    render() {
        return (
                <div className="row-contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="themesflat-contact-form style-2 w100 clearfix">
                                    <form action="#" method="post"
                                        className="contact-form wpcf7-form">
                                        <span className="wpcf7-form-control-wrap your-name">
                                            <input type="text" tabIndex={1} id="name" name="Name" defaultValue={""}
                                                className="wpcf7-form-control" placeholder="Name*" required onChange={(e) => this.onInputChangeHandlar(e)}/>
                                        </span>
                                        <span className="wpcf7-form-control-wrap your-email">
                                            <input type="email" tabIndex={3} id="email" name="Email" defaultValue={""}
                                                className="wpcf7-form-control" placeholder="Your Email*" required onChange={(e) => this.onInputChangeHandlar(e)}/>
                                        </span>
                                        <span className="wpcf7-form-control-wrap your-phone">
                                            <input type="text" tabIndex={2} id="phone" name="Mobile" defaultValue={""}
                                                className="wpcf7-form-control" placeholder="Your Phone*" onChange={(e) => this.onInputChangeHandlar(e)}/>
                                        </span>
                                        <span className="wpcf7-form-control-wrap your-message">
                                            <textarea name="Message" tabIndex={5} cols={40} rows={10}
                                                className="wpcf7-form-control wpcf7-textarea" placeholder="Message"
                                                defaultValue={""} onChange={(e) => this.onInputChangeHandlar(e)}/>
                                            </span>                                                             
                                <span className="wrap-submit">
                                    <input type="submit" defaultValue="SEND US" className="submit wpcf7-form-control wpcf7-submit" id="submit" name="submit" onClick={(e) => this.handleSubmit(e)}/>
                                </span>                                                            
                                </form>
                            </div>
                        </div>
                        <div className="col-md-8">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58779.43691380075!2d76.00752665103266!3d22.960728005869317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396317850c371de7%3A0x22947c209f24505!2sDewas%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1628052793451!5m2!1sen!2sin" 
        width="100%" height="100%" style={{border:0}}  loading="lazy"></iframe>
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                            <div className="themesflat-map style-2" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop={81} data-mobile={60} data-smobile={60} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormContact02;