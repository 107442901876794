import React, { Component  } from 'react'
import axios from 'axios';

class FormPopUp extends Component {
    constructor() {
        super();
          this.state = {
            Name: "",
            Mobile:"",
            Email: "",
            Message:""
          };
      }
  
      handleSubmit = (e) => {
          e.preventDefault();
          let emailPattern = new RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);
          let emailPattern2 = new RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+\.[A-Za-z]+$/);
          let emailPattern3 = new RegExp(/^[a-zA-Z0-9]+\.+[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]/);
          if(this.state.Name && this.state.Email && this.state.Mobile && (emailPattern.test(this.state.Email) || emailPattern2.test(this.state.Email)|| emailPattern3.test(this.state.Email))){
            alert("Thank You");  
            localStorage.setItem('RajeshreeMarketingPopUpRegisteration',this.state)
            axios.post("https://rajeshreebackend.pythonanywhere.com/app1/detail/adduser/",this.state)
              .then(res => {
                console.log("Done");   
              })
              .then(()=>window.location.href = `/`)
          }
            
      };
  
      onInputChangeHandlar = (event) => {
          const value = event.target.value;
          const name = event.target.name;
      
          this.setState({ [name]: value });
      };
    render() {
        return (
                <div className="row-contact" style={{position:'fixed',backgroundColor:'white',border:'1px solid',width:'90%',marginLeft:'5%',height:"80vh",marginTop:'10vh',display:'flex',alignItems:'center',zIndex:2,justifyContent:'center'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                            <h3>Please enter below required details</h3>
                                <div className="themesflat-contact-form style-2 w100 clearfix">
                                    <form action="#" method="post"
                                        className="contact-form wpcf7-form">
                                        <span className="wpcf7-form-control-wrap your-name">
                                            <input type="text" tabIndex={1} id="name" name="Name" defaultValue={""}
                                                className="wpcf7-form-control" placeholder="Name*" required onChange={(e) => this.onInputChangeHandlar(e)}/>
                                        </span>
                                        <span className="wpcf7-form-control-wrap your-email">
                                            <input type="email" tabIndex={3} id="email" name="Email" defaultValue={""}
                                                className="wpcf7-form-control" placeholder="Your Email*" required onChange={(e) => this.onInputChangeHandlar(e)}/>
                                        </span>
                                        <span className="wpcf7-form-control-wrap your-phone">
                                            <input type="text" tabIndex={2} id="phone" name="Mobile" defaultValue={""}
                                                className="wpcf7-form-control" placeholder="Your Phone*" onChange={(e) => this.onInputChangeHandlar(e)}/>
                                        </span>
                                        <span className="wpcf7-form-control-wrap your-message">
                                            <textarea name="Message" tabIndex={5} cols={40} rows={10}
                                                className="wpcf7-form-control wpcf7-textarea" placeholder="Message"
                                                defaultValue={""} onChange={(e) => this.onInputChangeHandlar(e)}/>
                                            </span>                                                             
                                <span className="wrap-submit">
                                    <input type="submit" defaultValue="SEND US" className="submit wpcf7-form-control wpcf7-submit" id="submit" name="submit" onClick={(e) => this.handleSubmit(e)}/>
                                </span>                                                            
                                </form>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        );
    }
}

export default FormPopUp;