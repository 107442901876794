import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import icon from "../../../assets/logo_v2.png"
class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inner: [
                {
                    id: 1,
                    icon: 'fa fa-check-square',
                    text: 'Find your dream house at a reasonable price.'
                },
                {
                    id: 2,
                    icon: 'fa fa-check-square',
                    text: 'Get loan for it at a very low rate.'
                },
                {
                    id: 3,
                    icon: 'fa fa-check-square',
                    text: 'Find a property across the city.'
                },
                {
                    id: 4,
                    icon: 'fa fa-check-square',
                    text: 'Sell your Property at a reasonable price.'
                },
            ]
        }
    }
    
    render() {
        return (
          <div className="row-about">
            <div className="container-fluid">
              <div className="row equalize sm-equalize-auto">
                <div className="col-md-6 half-background style-1" style={{backgroundImage:`url(${icon})`,backgroundSize:'contain'}} />
                <div className="col-md-6 bg-light-grey">
                  <div
                    className="themesflat-spacer clearfix"
                    data-desktop={64}
                    data-mobile={60}
                    data-smobile={60}
                  />
                  <div
                    className="themesflat-content-box clearfix"
                    data-margin="0 25% 0 4.5%"
                    data-mobilemargin="0 0 0 4.5%"
                  >
                    <div className="themesflat-headings style-1 clearfix">
                      <h2 className="heading">WELCOME TO RAJESHREE</h2>
                      <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />
                      <p className="sub-heading margin-top-30">
                        A place where you will find your dream house at a
                        effective price. We have a variety of colonies across
                        the city with a lot of facilities at a very reasonable
                        cost. We also provide Loans at a reasonable rate so you
                        will be able to get your dream house soon. We also
                        Buy/Sell properties. At RAJESHREE you can :
                      </p>
                    </div>
                    <div
                      className="themesflat-spacer clearfix"
                      data-desktop={26}
                      data-mobile={35}
                      data-smobile={35}
                    />
                    <div className="content-list">
                      <div className="themesflat-list has-icon style-1 icon-left clearfix">
                        <div className="inner">
                          {this.state.inner.map((data) => (
                            <span className="item" key={data.id}>
                              <span className="icon">
                                <i className={data.icon} />
                              </span>
                              <span className="text">{data.text}</span>
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div
                      className="themesflat-spacer clearfix"
                      data-desktop={42}
                      data-mobile={35}
                      data-smobile={35}
                    />
                    <div className="elm-button">
                      <Link
                        to="#"
                        className="themesflat-button bg-white"
                        onClick={() => {
                          window.location.href = `/contact-02`;
                        }}
                      >
                        GET IN TOUCH
                      </Link>
                    </div>
                  </div>
                  <div
                    className="themesflat-spacer clearfix"
                    data-desktop={75}
                    data-mobile={60}
                    data-smobile={60}
                  />
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default About;