import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import menus from '../menu'
import logo from "../../../assets/logo_v2.png";

class Header extends Component {
    render() {
        const { location } = this.props;
        return (
          <header id="site-header">
            <div id="site-header-inner" className="container">
              <div className="wrap-inner clearfix" >
                <div id="site-logo" className="clearfix">
                  <div id="site-log-inner">
                    <Link
                      to="#"
                      onClick={() => {
                        window.location.href = "/";
                      }}
                      rel="home"
                      className="main-logo"
                    >
                      {/* <div style={{height:72,width:92,backgroundImage:`url(${logo})`,backgroundSize:'contain'}}></div> */}
                      <img
                        src={logo}
                        alt="Rajshree"
                        width={82}
                        height={49}
                        // data-width={186}
                        // data-height={39}
                      />
                    </Link>
                  </div>
                </div>
                <div className="mobile-button">
                  <span />
                </div>
                <nav id="main-nav" className="main-nav">
                  <ul id="menu-primary-menu" className="menu">
                    {menus.map((data) => (
                      <li
                        className={
                          data.name === this.props.data.names
                            ? "menu-item menu-item-has-children current-menu-item"
                            : "menu-item menu-item-has-children"
                        }
                        key={data.id}
                      >
                        <Link
                          Link
                          to="#"
                          onClick={() => {
                            window.location.href = data.links;
                          }}
                        >
                          {data.name}
                        </Link>
                        {/* <ul className="sub-menu">
                                                {
                                                    data.namesub.map(submenu => (
                                                        <li className={location.pathname === submenu.links ? "menu-item current-item" : "menu-item"}  key={submenu.id} ><Link to={submenu.links}  onClick={() => {window.location.href=submenu.links}}>{submenu.sub}</Link></li>
                                                    ))
                                                }
                                            </ul> */}
                      </li>
                    ))}
                  </ul>
                </nav>
                {/* <div id="header-search">
                        <Link to="#" className="header-search-icon">
                            <span className="search-icon fa fa-search">
                            </span>
                        </Link>
                        <form role="search" method="get" className="header-search-form" action="#">
                            <label className="screen-reader-text">Search for:</label>
                            <input type="text" defaultValue name="s" className="header-search-field" placeholder="Search..." />
                            <button type="submit" className="header-search-submit" title="Search"><i className="fa fa-search" /></button>
                        </form>
                        </div> */}
              </div>
            </div>
          </header>
        );
    }
}

export default withRouter(Header);