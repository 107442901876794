import React, { Component } from 'react';
import axios from 'axios';

class FormContact extends Component {
    constructor() {
        super();
          this.state = {
            Name: "",
            Mobile:"",
            Email: "",
            Message:""
          };
      }
  
      handleSubmit = (e) => {
          e.preventDefault();
          let emailPattern = new RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);
          let emailPattern2 = new RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+\.[A-Za-z]+$/);
          let emailPattern3 = new RegExp(/^[a-zA-Z0-9]+\.+[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]/);
          if(this.state.Name && this.state.Email && this.state.Mobile && (emailPattern.test(this.state.Email) || emailPattern2.test(this.state.Email)|| emailPattern3.test(this.state.Email))){
            alert("Thank You");  
            axios.post("https://rajeshreebackend.pythonanywhere.com/app1/detail/adduser/",this.state)
              .then(res => {
                console.log("Done");   
              })
          }
      };
  
      onInputChangeHandlar = (event) => {
          const value = event.target.value;
          const name = event.target.name;
      
          this.setState({ [name]: value });
      };
    render() {
        return (
            <div className="col-md-12">
                <div className="themesflat-headings style-2 clearfix">
                    <h2 className="heading">CONTACT US</h2>
                    <div className="sep has-width w80 accent-bg clearfix" />
                    <p className="sub-heading">Are you interested in finding out how Rajeshree Services can make your
                        project. For more information on our services please contact us.</p>
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={36} data-mobile={35} data-smobile={35} />
                <div className="themesflat-contact-form style-2 clearfix">
                    <form action="#" method="post" className="form-submit contact-form wpcf7-form">
                        <span className="wpcf7-form-control-wrap your-name">
                            <input type="text" tabIndex={1} id="name" name="Name" defaultValue={""} className="wpcf7-form-control"
                                placeholder="name*" required onChange={(e) => this.onInputChangeHandlar(e)}/>
                        </span>
                        <span className="wpcf7-form-control-wrap your-phone">
                            <input type="text" tabIndex={2} id="phone" name="Mobile" defaultValue={""} className="wpcf7-form-control"
                                placeholder="Phone" required onChange={(e) => this.onInputChangeHandlar(e)}/>
                        </span>
                        <span className="wpcf7-form-control-wrap your-email" style={{width: "100%"}}>
                            <input type="email" tabIndex={3} id="email" name="Email" defaultValue={""}
                                className="wpcf7-form-control" placeholder="Your email*" required onChange={(e) => this.onInputChangeHandlar(e)}/>
                        </span>
                        {/* <span className="wpcf7-form-control-wrap your-subject">
                            <input type="text" tabIndex={4} id="subject" name="subject" defaultValue={""}
                                className="wpcf7-form-control" placeholder="Subject" onChange={(e) => this.onInputChangeHandlar(e)}/>
                        </span> */}
                        <span className="wpcf7-form-control-wrap your-message">
                            <textarea name="Message" tabIndex={5} cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea"
                                placeholder="message" defaultValue={""} onChange={(e) => this.onInputChangeHandlar(e)}/>
                            </span>                                                             
                        <span className="wrap-submit">
                        <input type="submit" defaultValue="SEND US" className="submit wpcf7-form-control wpcf7-submit" id="submit" name="submit" onClick={(e) => this.handleSubmit(e)}/>
                        </span>                                                            
                    </form>
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
            </div>
        );
    }
}

export default FormContact;