import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import logo from "../../../assets/logo_v2.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
     colonies: [],
      offers: [],
      
    };
  }

  componentDidMount() {
    fetch("https://rajeshreebackend.pythonanywhere.com/app1/detail/colony/")
      .then((res) => res.json())
      .then((data) => {
       
        this.setState((curstate) => {
          return {
            ...curstate,
            colonies: data,
          };
        });
      })
      .catch((err) => {
          toast.error("Error while Loading Data");  
      });
    fetch("https://rajeshreebackend.pythonanywhere.com/app1/detail/offers/")
      .then((res) => res.json())
      .then((data) => {
     
        this.setState((curstate) => {
          return {
            ...curstate,
            offers: data
          };
        });
      })
      .catch((err) => {
         toast.error("Error while Loading Data");
      });
  }
  render() {
    return (
      <footer id="footer" className="clearfix">
        <div id="footer-widgets" className="container">
          <div className="themesflat-row gutter-30">
            <div className="col span_1_of_3">
              <div className="widget widget_text">
                <div className="textwidget">
                  <p>
                    <img src={logo} alt="imagealt" width={170} height={34} />
                  </p>
                  <p className="margin-bottom-15">
                    We have over 10 years of experien able to help you 24 hours
                    a day.
                  </p>
                  <ul>
                    <li>
                      <div className="inner">
                        <span className="fa fa-map-marker" />
                        <span className="text">
                          05 Balaji Nagar Main Road, Balgarh Main Road,Dewas
                          <br /> (Opposite Punjab Furnitures)
                          {/* <span className="sl">West Victoria, NewYork</span> */}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <span className="fa fa-phone" />
                        <span className="text">CALL US : (+91) 9826072384</span>
                        <br></br>
                        <span className="text"> (+91) 9755710501</span>
                      </div>
                    </li>
                    <li className="margin-top-7">
                      <div className="inner">
                        <span className=" font-size-14 fa fa-envelope" />
                        <span className="text">
                          Rajeshreemarketings@gmail.com
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /.widget_text */}
              <div
                className="themesflat-spacer clearfix"
                data-desktop={0}
                data-mobile={0}
                data-smobile={35}
              />
            </div>
            {/* /.col */}
            <div className="col span_1_of_3">
              <div
                className="themesflat-spacer clearfix"
                data-desktop={0}
                data-mobile={0}
                data-smobile={0}
              />
              <div className="widget widget_lastest">
                <h2 className="widget-title">
                  <span>LATEST COLONIES</span>
                </h2>
                {this.state.colonies.length === 0 && <p>Coming soon..</p>}
                {this.state.colonies.length >= 1 && (
                  <ul className="lastest-posts data-effect clearfix">
                    <li className="clearfix">
                      <div className="thumb data-effect-item has-effect-icon">
                        <img
                          src={
                            "https://rajeshreebackend.pythonanywhere.com/" +
                            this.state.colonies[this.state.colonies.length - 1]
                              .colony_images[0].Image
                          }
                          alt="imagealt"
                        />
                        <div className="overlay-effect bg-color-2" />
                        <div className="elm-link">
                          <Link
                            to="#"
                            onClick={() => {
                              window.location.href = `/colonies/${
                                this.state.colonies[
                                  this.state.colonies.length - 1
                                ].id
                              }`;
                            }}
                            className="icon-2"
                          />
                        </div>
                      </div>
                      <div
                        className="text"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.location.href = `/colonies/${
                            this.state.colonies[this.state.colonies.length - 1]
                              .id
                          }`;
                        }}
                      >
                        <h3>
                          <Link
                            to="#"
                            onClick={() => {
                              window.location.href = `/colonies/${
                                this.state.colonies[
                                  this.state.colonies.length - 1
                                ].id
                              }`;
                            }}
                          >
                            {
                              this.state.colonies[
                                this.state.colonies.length - 1
                              ].Name
                            }
                          </Link>
                        </h3>
                        <span className="post-date">
                          <span className="entry-date">
                            {
                              this.state.colonies[
                                this.state.colonies.length - 1
                              ].Address
                            }
                          </span>
                          <br />
                          <span className="entry-date">
                            {
                              this.state.colonies[
                                this.state.colonies.length - 1
                              ].Place
                            }
                          </span>
                        </span>
                      </div>
                    </li>
                    {this.state.colonies.length >= 2 && (
                      <li className="clearfix">
                        <div className="thumb data-effect-item has-effect-icon">
                          <img
                            src={
                              "https://rajeshreebackend.pythonanywhere.com/" +
                              this.state.colonies[
                                this.state.colonies.length - 2
                              ].colony_images[0].Image
                            }
                            alt="imagealt"
                          />
                          <div className="overlay-effect bg-color-2" />
                          <div className="elm-link">
                            <Link
                              to="#"
                              onClick={() => {
                                window.location.href = `/colonies/${
                                  this.state.colonies[
                                    this.state.colonies.length - 2
                                  ].id
                                }`;
                              }}
                              className="icon-2"
                            />
                          </div>
                        </div>
                        <div
                          className="text"
                          onClick={() => {
                            window.location.href = `/colonies/${
                              this.state.colonies[
                                this.state.colonies.length - 2
                              ].id
                            }`;
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <h3>
                            <Link
                              to="#"
                              onClick={() => {
                                window.location.href = `/colonies/${
                                  this.state.colonies[
                                    this.state.colonies.length - 2
                                  ].id
                                }`;
                              }}
                            >
                              {
                                this.state.colonies[
                                  this.state.colonies.length - 2
                                ].Name
                              }
                            </Link>
                          </h3>
                          <span className="post-date">
                            <span className="entry-date">
                              {" "}
                              {
                                this.state.colonies[
                                  this.state.colonies.length - 2
                                ].Address
                              }
                            </span>
                            <br />
                            <span className="entry-date">
                              {" "}
                              {
                                this.state.colonies[
                                  this.state.colonies.length - 2
                                ].Place
                              }
                            </span>
                          </span>
                        </div>
                      </li>
                    )}
                  </ul>
                )}
              </div>
              {/* /.widget_lastest */}
            </div>
            {/* /.col */}
            <div className="col span_1_of_3">
              <div
                className="themesflat-spacer clearfix"
                data-desktop={0}
                data-mobile={35}
                data-smobile={35}
              />
              <div className="widget widget_tags">
                <h2 className="widget-title margin-bottom-30">
                  <span>OFFERS</span>
                </h2>
                <div className="tags-list">
                  {this.state.offers.length >= 1 &&
                    this.state.offers.map((data) => {
                      return (
                        <Link key={data.id} to="#">
                          {data.Type} : {data.Offer}
                        </Link>
                      );
                    })}
                  {this.state.offers.length === 0 && <p>Coming soon..</p>}
                </div>
              </div>
            </div>
            {/* /.col */}
            <div className="col span_1_of_3">
              <div
                className="themesflat-spacer clearfix"
                data-desktop={0}
                data-mobile={35}
                data-smobile={35}
              />
              <div className="widget widget_instagram">
                <h2 className="widget-title margin-bottom-30">
                  <span>REACH US</span>
                </h2>
                <div className="instagram-wrap data-effect clearfix col3 g10">
                  <div className="instagram_badge_image has-effect-icon ">
                    <a
                      href="https://www.facebook.com/AnandViharLakeView"
                      className="link-social"
                      target={"_blank"}
                    >
                      <i className="fa fa-facebook" /> Facebook
                    </a>
                    {/* <Link to="#" className="link-social">
                      <i className="fa fa-twitter" /> Twitter
                    </Link> */}

                    <a
                      href="https://www.instagram.com/rajeshreemarketing/"
                      className="link-social"
                      target={"_blank"}
                    >
                      <i className="fa fa-instagram" /> Instagram
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;