import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import { FormContact00 } from '../../contact';
class SideBarr extends Component {
    constructor(props) {
        super(props);
        this.state = {
         colonies: [],
          offers: [],
        };
      }
    
      componentDidMount() {
        fetch("https://rajeshreebackend.pythonanywhere.com/app1/detail/colony/")
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            this.setState((curstate) => {
              return {
                ...curstate,
                colonies: data,
              };
            });
          })
          .catch((err) => {
            console.log(err);
          });
        fetch("https://rajeshreebackend.pythonanywhere.com/app1/detail/offers/")
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            this.setState((curstate) => {
              return {
                ...curstate,
                offers: data
              };
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    render() {
        return (
            <div id="sidebar">
                {/* <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={0} data-smobile={0} /> */}
                {/* <div className="themesflat-headings style-1 text-center clearfix">
                    <h2 className="heading">Limited Time Offers</h2>
                    <div className="sep has-icon width-125 clearfix">
                        <div className="sep-icon">
                            <span className="sep-icon-before sep-center sep-solid" />
                            <span className="icon-wrap"><i className="autora-icon-time" /></span>
                            <span className="sep-icon-after sep-center sep-solid" />
                        </div>
                    </div>                                              
                </div> */}
                <div id="inner-sidebar" className="inner-content-wrap">
                                {/* <li className="list-item">
                                    <Link to="#"><span className="text">Lorem, ipsum dolor.</span></Link>
                                </li>
                                <li className="list-item">
                                    <Link to="#"><span className="text">Lorem ipsum dolor sit.</span></Link>
                                </li>
                                <li className="list-item">
                                    <Link to="#"><span className="text">Lorem ipsum dolor sit amet consectetur adipisicing elit.</span></Link>
                                </li> */}
                                {/* {<div
                                id="inner-sidebar"
                                className="inner-content-wrap"
                              >
                                <div className="widget widget_list">
                                  <div
                                    className="inner"
                                    style={{
                                      marginBottom: "100px",
                                      backgroundColor: "rgb(255 204 67)",
                                    }}
                                  >
                                    <ul className="list-wrap">
                                    { this.state.offers.map((data)=>{
                                      return (
                                        <li key={data.id} className="list-item">
                                          <Link key={data.id} to="#">
                                            <span
                                              key={data.id}
                                              className="text"
                                            >
                                              {data.Offer}
                                            </span>
                                          </Link>
                                        </li>
                                      );
                                    })}
                                    </ul>
                                  </div>
                                </div>
                              </div>} */}
                    <div className="themesflat-spacer clearfix" data-desktop={81} data-mobile={60} data-smobile={60} />
                    <div style={{margin:'auto !important'}}>
                        <FormContact00/>
                    </div>
                    <br/>
                </div>
                <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={60} data-smobile={60} />
            </div>
        );
    }
}

export default SideBarr;