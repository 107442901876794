import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Parser from "html-react-parser";
import video_image from '../../../assets/video_image.png'

class ContentProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inner: this.props.colony.Facilities.split(","),
      outer: this.props.colony.Amenities.split(","),
    };
    
  this.customThumbs=()=>{
    // console.log(imge,'dkfj')
    this.props.colony.colony_images.map((data)=>{
      // let url=null;
      // {data.Image.split('').reverse().join('').substr(0,3)==='gpj' || data.Image.split('').reverse().join('').substr(0,4)==='gepj'|| data.Image.split('').reverse().join('').substr(0,3)==='gnp'? 
      //   url = "https://rajeshreebackend.pythonanywhere.com/" + data.Image:
      //   url=null
      //   // console.log({video_image})
      //   // url = video_image
      // }
      return <img src={video_image}/>
    })
  }
  }

  render() {
   
    
    return (
      <div className="detail-gallery">
        <div
          className="themesflat-spacer clearfix"
          data-desktop={21}
          data-mobile={21}
          data-smobile={21}
        />
        <div
          className="themesflat-gallery style-2 has-arrows arrow-center arrow-circle offset-v-82 has-thumb w185 clearfix"
          data-gap={0}
          data-column={1}
          data-column2={1}
          data-column3={1}
          data-auto="false"
        >
          {/* <div className="owl-carousel owl-theme">
            {this.props.colony.colony_images.map((data) => {
              let url =
                "https://rajeshreebackend.pythonanywhere.com/" + data.Image;
              return (
                <div className="gallery-item" key={data.id}>
                  <div className="inner" key={data.id}>
                    <div className="thumb" key={data.id}>
                      <img src={url} alt="imagealt" key={data.id} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
          
          <Carousel showArrows={false} autoPlay={true} renderThumbs={this.customThumbs}>
            {this.props.colony.colony_images.map((data) => {
              let url =
                "https://rajeshreebackend.pythonanywhere.com/" + data.Image;
            
                
              return (
                <div key={data.id} >
                 {data.Image.split('').reverse().join('').substr(0,3)==='gpj' || data.Image.split('').reverse().join('').substr(0,4)==='gepj'|| data.Image.split('').reverse().join('').substr(0,3)==='gnp'? <img src={url} />:null}
                 {data.Image.split('').reverse().join('').substr(0,3)==='vkm' || data.Image.split('').reverse().join('').substr(0,3)==='4pm'?
                  <video   height="300" controls>
                  <source  src={url} type="video/mp4"/>
                Your browser does not support the video tag.
                </video> 
                 :null}
                 {data.Image.split('').reverse().join('').substr(0,3)==='vkm' || data.Image.split('').reverse().join('').substr(0,3)==='4pm'?<img src="" />:null}
                 {data.Description?<p style={{wordWrap:'break-word'}}>{data.Description}</p>:null}
                 
                </div>
              );
            })}
          </Carousel>
        </div>
        {/* /.themesflat-cousel-box */}
        <div
          className="themesflat-spacer clearfix"
          data-desktop={40}
          data-mobile={40}
          data-smobile={40}
        />
        <div
          className="row"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent:
              window.innerWidth < 500 ? "flex-start" : "space-around",
            alignItems: "center",
            marginTop: "15px",
            marginLeft: window.innerWidth < 500 ? "15px" : "0px",
          }}
        >
          <div className="column">
            <div className="themesflat-headings style-2 clearfix">
              <h2 className="heading line-height-62">Facilities</h2>
              <div className="sep has-width w80 accent-bg clearfix"></div>
            </div>
            <div className="content-list">
              <div className="themesflat-list has-icon style-1 icon-left clearfix">
                <div className="inner">
                  {this.state.inner.map((data, idx) => (
                    <span className="item" key={idx}>
                      <span className="icon">
                        <i className="fa fa-check-square" />
                      </span>
                      <span className="text">{data}</span>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="themesflat-headings style-2 clearfix">
              <h2 className="heading line-height-62">Amenities</h2>
              <div className="sep has-width w80 accent-bg clearfix"></div>
            </div>
            <div className="content-list">
              <div className="themesflat-list has-icon style-1 icon-left clearfix">
                <div className="inner">
                  {this.state.outer.map((data, idx) => (
                    <span className="item" key={idx}>
                      <span className="icon">
                        <i className="fa fa-check-square" />
                      </span>
                      <span className="text">{data}</span>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "50px" }} className="content">
            {<div className="content">{Parser(this.props.colony.Map)}</div>}
          </div>
         
        </div>
      </div>
    );
  }
}

export default ContentProject;
