import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BannerTitle from '../layouts/about/banner/BannerTitle'
import {Header, Footer, TopBar, BottomBar} from '../layouts/general/index'
import {Sidebar, SiteContent} from '../layouts/services/index';
import { FormContact00 } from '../layouts/contact';

class ServiceDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo-small.png",
                    names: "Finance",
                }
            ],
            banners: [
                {
                    id: 1,
                    links: '',
                    titlelink: '',
                    border: '',
                    name: 'Finance',
                    heading: 'Finance'
                }
            ],
            filter: "ALL",
            offers:[]
        }
    }
    componentDidMount() {
        fetch("https://rajeshreebackend.pythonanywhere.com/app1/detail/offers/")
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            this.setState((curstate) => {
              return {
                ...curstate,
                offers: data.filter((value)=>{return value.Type==="finance"}),
              };
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    render() {
        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
                <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            <TopBar />
                            {
                                this.state.headers.map((data,idx) =>(
                                    <Header data={data} key={idx}/>
                                ))
                            }
                        </div>
                        {
                            this.state.banners.map(data =>(
                                <BannerTitle key={data.id} data={data}/>
                            ))
                        }
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap" className="container">
                            <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={27} data-smobile={27} />
                            {this.state.offers.length>=1&& <div className="themesflat-headings style-1 text-center clearfix">
                                <h2 className="heading">Limited Time Offers</h2>
                                <div className="sep has-icon width-125 clearfix">
                                  <div className="sep-icon">
                                    <span className="sep-icon-before sep-center sep-solid" />
                                    <span className="icon-wrap">
                                      <i className="autora-icon-time" />
                                    </span>
                                    <span className="sep-icon-after sep-center sep-solid" />
                                  </div>
                                </div>
                              </div>}


                              {this.state.offers.length>=1&&  <div
                                id="inner-sidebar"
                                className="inner-content-wrap"
                              >
                                <div className="widget widget_list">
                                  <div
                                    className="inner"
                                    style={{
                                      marginBottom: "100px",
                                      backgroundColor: "rgb(255 204 67)",
                                    }}
                                  >
                                    <ul className="list-wrap">
                                    { this.state.offers.map((data)=>{
                                      return (
                                        <li key={data.id} className="list-item">
                                          <Link key={data.id} to="#">
                                            <span
                                              key={data.id}
                                              className="text"
                                            >
                                              {data.Offer}
                                            </span>
                                          </Link>
                                        </li>
                                      );
                                    })}
                                    </ul>
                                  </div>
                                </div>
                              </div>}
                            {/* <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} /> */}
                                <div className="themesflat-headings style-1 text-center clearfix">
                                    <h2 className="heading">Finance</h2>
                                    <div className="sep has-icon width-125 clearfix">
                                        <div className="sep-icon">
                                            <span className="sep-icon-before sep-center sep-solid" />
                                            <span className="icon-wrap"><i className="autora-icon-build-home" /></span>
                                            <span className="sep-icon-after sep-center sep-solid" />
                                        </div>
                                    </div>                                              
                                </div>
                                <SiteContent />
                               <FormContact00/>

                            </div>
                        </div>
                        <div className="themesflat-spacer clearfix" data-desktop={27} data-mobile={27} data-smobile={27} />

                        <Footer />
                        {
                            this.state.headers.map((data,idx) =>(
                                <BottomBar data={data} key={idx}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceDetail;