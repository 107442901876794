import React from "react";
import {BrowserRouter, Route} from 'react-router-dom'
import routes from './components/pages/index'
import { useToasts,ToastProvider } from "react-toast-notifications";
function App() {
  const {addToast}=useToasts();
// return <div><b>Site is down due to financial reasons</b> </div>
 return (
   <BrowserRouter>
     {
       routes.map((data,idx) => (
         <Route exact path={data.path} addToast={addToast} component={data.component} key={idx}></Route>
        ))
     }

   </BrowserRouter>
 )
}

export default App;
