import React, { Component } from "react";
import { Link } from "react-router-dom";
import BannerTitle from "../layouts/about/banner/BannerTitle";
import { Header, Footer, TopBar, BottomBar } from "../layouts/general/index";
import { TabProject } from "../layouts/home02";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
toast.configure();
class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          logoweb: "assets/img/logo-small.png",
          names: "Colonies",
        },
      ],
      banners: [
        {
          id: 1,
          links: "",
          titlelink: "",
          border: "",
          name: "Colonies",
          heading: "Colonies",
        },
      ],

      colonies: [],
      filter: "ALL",
      offers: [],
      loading1:true,
      loading2:true,
    };
  }

  componentDidMount() {
    this.setState((prev)=>{return {...prev,loading1:true,loading2:true}});
    fetch("https://rajeshreebackend.pythonanywhere.com/app1/detail/colony/")
      .then((res) => res.json())
      .then((data) => {
        
        this.setState((prev) => {
          return { ...prev, loading1: false };
        });
        this.setState((curstate) => {
          return {
            ...curstate,
            colonies: data,
          };
        });
      })
      .catch((err) => {
        this.setState((prev) => {
          return { ...prev, loading1: false };
        });
        toast.error("Error while Loading Data")
      });
    fetch("https://rajeshreebackend.pythonanywhere.com/app1/detail/offers/")
      .then((res) => res.json())
      .then((data) => {
       
        this.setState((prev) => {
          return { ...prev,  loading2: false };
        });
        this.setState((curstate) => {
          return {
            ...curstate,
            offers: data.filter((value) => {
              return value.Type === "colony";
            }),
          };
        });
      })
      .catch((err) => {
        this.setState((prev) => {
          return { ...prev,  loading2: false };
        });
         toast.error("Error while Loading Data");
      });
  }
  render() {
    let uniqueCities = ["ALL"];
    if (this.state.colonies.length !== 0) {
      this.state.colonies.map((data) => {
        let city = data.Place.split(",")[0];
        
        if (
          uniqueCities.find(
            (a) => a.trim().toUpperCase() === city.trim().toUpperCase()
          ) === undefined
        ) {
          uniqueCities.push(city.trim().toUpperCase());
         
        }
      });
    }
    return (
      <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
        <div id="wrapper" className="animsition">
          <div id="page" className="clearfix">
            <div id="site-header-wrap">
              <TopBar />
              {this.state.headers.map((data, idx) => (
                <Header data={data} key={idx} />
              ))}
            </div>
            {this.state.banners.map((data) => (
              <BannerTitle key={data.id} data={data} />
            ))}
            <div id="main-content" className="site-main clearfix">
              <div id="content-wrap">
                <div id="site-content" className="site-content clearfix">
                  <div id="inner-content" className="inner-content-wrap">
                    <div className="page-content">
                      <div className="row-services">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="themesflat-spacer clearfix"
                                data-desktop={73}
                                data-mobile={60}
                                data-smobile={60}
                              />

                              {this.state.offers.length >= 1 && (
                                <div className="themesflat-headings style-1 text-center clearfix">
                                  <h2 className="heading">
                                    Limited Time Offers
                                  </h2>
                                  <div className="sep has-icon width-125 clearfix">
                                    <div className="sep-icon">
                                      <span className="sep-icon-before sep-center sep-solid" />
                                      <span className="icon-wrap">
                                        <i className="autora-icon-time" />
                                      </span>
                                      <span className="sep-icon-after sep-center sep-solid" />
                                    </div>
                                  </div>
                                </div>
                              )}

                              {this.state.offers.length >= 1 && (
                                <div
                                  id="inner-sidebar"
                                  className="inner-content-wrap"
                                >
                                  <div className="widget widget_list">
                                    <div
                                      className="inner"
                                      style={{
                                        marginBottom: "100px",
                                        backgroundColor: "rgb(255 204 67)",
                                      }}
                                    >
                                      <ul className="list-wrap">
                                        {this.state.loading2 && (
                                          <ReactLoading
                                            type="spin"
                                            color="yellow"
                                          ></ReactLoading>
                                        )}
                                        {this.state.offers.map((data) => {
                                          return (
                                            <li
                                              key={data.id}
                                              className="list-item"
                                            >
                                              <Link key={data.id} to="#">
                                                <span
                                                  key={data.id}
                                                  className="text"
                                                >
                                                  {data.Offer}
                                                </span>
                                              </Link>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <ul className="row style-1 clearfix">
                                {uniqueCities.length !== 0 &&
                                  uniqueCities.map((data) => {
                                    let cityh = "." + data.trim().toUpperCase();
                                   
                                    return (
                                      <li
                                        style={{
                                          margin: "10px",
                                          fontSize: "large",
                                        }}
                                        key={cityh}
                                      >
                                        <Link
                                          style={{
                                            color:
                                              this.state.filter === data
                                                ? "#ffcd00"
                                                : "black",
                                          }}
                                          to="#"
                                          onClick={(e) => {
                                         
                                            // e.preventDefault();
                                            this.setState((prev) => {
                                              return {
                                                ...prev,
                                                filter: data
                                                  .trim()
                                                  .toUpperCase(),
                                              };
                                            });
                                          }}
                                        >
                                          {data}
                                        </Link>
                                      </li>
                                    );
                                  })}
                              </ul>
                              <div
                                className="themesflat-spacer clearfix"
                                data-desktop={40}
                                data-mobile={35}
                                data-smobile={35}
                              />
                              {this.state.loading1 && (
                                <ReactLoading
                                  type="spin"
                                  color="yellow"
                                ></ReactLoading>
                              )}
                              {this.state.colonies.length !== 0 && (
                                <TabProject
                                  filter={this.state.filter}
                                  colonies={this.state.colonies}
                                />
                              )}
                              {/* <div className="button-wrap has-icon icon-left size-14 pf21 text-center">
                                                            <Link to="#" className="themesflat-button bg-accent pd32"><span>LOAD MORE <span className="icon"><i className="ion-load-c" /></span></span></Link>
                                                        </div>                                            */}
                              <div
                                className="themesflat-spacer clearfix"
                                data-desktop={72}
                                data-mobile={60}
                                data-smobile={60}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            {this.state.headers.map((data, idx) => (
              <BottomBar data={data} key={idx} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Projects;
