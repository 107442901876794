import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class IconBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
          iconbox: [
            {
              id: 1,
              icon: "autora-icon-quality",
              title: "Trustworthy",
              text: "You get what you expect,every time because we value your trust.",
            },
            {
              id: 2,
              icon: "autora-icon-time",
              title: "constantly modernizing",
              text: "We inform about all latest assests at first. ",
            },
            {
              id: 3,
              icon: "autora-icon-author",
              title: "Strong Work Ethic",
              text: "	We are  commitment and dedication to the job and thus reliable and above it an emphasis on quality.",
            },
          ],
        };
    }
    
    render() {
        return (
            <div className="row">
                {
                    this.state.iconbox.map(data => (
                        <div className="col-md-4" key={data.id}>
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                            <div className="themesflat-content-box clearfix" data-margin="0 5px 0 5px" data-mobilemargin="0 0 0 0">
                                <div className="themesflat-icon-box icon-top align-center has-width w95 circle light-bg accent-color style-1 clearfix">
                                    <div className="icon-wrap">
                                        <i className={data.icon} />
                                    </div>
                                    <div className="text-wrap">
                                        <h5 className="heading"><Link to="#">{data.title}</Link></h5>
                                        <div className="sep clearfix" />
                                        <p className="sub-heading">{data.text}</p>
                                    </div>
                                </div>
                            </div>                                       
                        </div>  
                    ))
                }                    
            </div>
        );
    }
}

export default IconBox;