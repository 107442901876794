import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import img from "../../../assets/rajeshree_logo_updated_3.png";

import home from "../../../assets/loans/home.jpg";
import personal from "../../../assets/loans/personal.jpg";
import education from "../../../assets/loans/education.jpg";
import business from "../../../assets/loans/bsuniess.jpg";
import mortgage from "../../../assets/loans/mortgage.jpg";
import project from "../../../assets/loans/project.jpg";
import bttop from "../../../assets/loans/bttop.jpg";
import gold from "../../../assets/loans/gold.jpg";
import car from "../../../assets/loans/car.jpg";
import pension from "../../../assets/loans/pension.jpg";


class SiteContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {
                    id: 1,
                    text01: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    text02: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.'
                },
                {
                    id: 2,
                    text01: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    text02: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.'
                },
                {
                    id: 3,
                    text01: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                    text02: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.'
                }
            ]
        }
    }
    
    render() {
        return (
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="themesflat-spacer clearfix" data-desktop={30} data-mobile={30} data-smobile={30} />
                    <div className="themesflat-row equalize sm-equalize-auto clearfix">
                        <div className="span_2_of_6 bg-f7f">
                            <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 10px 0 43px"
                                data-mobilemargin="0 15px 0 15px">
                                <div className="themesflat-headings style-2 clearfix">
                                    {/* <div className="sup-heading">SPECIAL SERVICES</div> */}
                                    <h2 className="heading font-size-28 line-height-39">Financial Consultancy</h2>
                                    <div className="sep has-width w80 accent-bg margin-top-20 clearfix" />
                                    <p className="sub-heading margin-top-33 line-height-24">
                                        We Provide various types of loans including Home Loan, Personal Loan, Education Loan,
                                        Business Loan, Mortage Loan, Project Loan, BT Topup Loan, 
                                        Gold Loan, Car Loan, Pension Loan and Many More.
                                    </p>
                                    <br/>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={56} data-mobile={56} data-smobile={56} />
                        </div>
                        {/* <div className="span_1_of_6 half-background style-2">
                        </div> */}
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={39} data-mobile={39} data-smobile={39} />
                    <div className="row equalize sm-equalize-auto">
                        <div className='col-md-9 bg-light-gray'>
                            <h5 className="sub-heading"><span style={{fontSize:"100%",color:"black"}}>&#9776;</span> Home Loan</h5>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> KYC (Adhar card,Pan card,voter id, 2 photos, electricity bill, bank statement 1 year update,)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Income Proof (salary slip of 6 months/Income tax return 3 yrs with P&L balance sheet,form no. 16 of 3 months, Loan statements)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Property Paper (Registry, namankan, Property and water tax slip, approved map by Muncipal Coorporation, permission certificate(Anugya praman patra),Chain Registry/Colony Master File</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> List of Document and Loan Statement of running all/ NOC of close.</li>
                        </div>
                        <div className='col-md-3 style-1'>
                            <img src={home} alt="photo"></img>
                        </div>
                    </div>
                    
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    
                    <div className="row equalize sm-equalize-auto">
                        <div className='col-md-9 bg-light-gray'>
                            <h5 className="sub-heading"><span style={{fontSize:"100%",color:"black"}}>&#9776;</span> Personal Loan</h5>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> KYC (Adhar card,Pan card,voter id, 2 photos, electricity bill, bank statement 1 year update)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Income Proof (salary slip of 6 months/Income tax return 3 yrs with P&L balance sheet,form no. 16 of 3 months, Loan statements)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> List of Document and Loan Statement of running all/ NOC of close.</li>
                        </div>
                        <div className='col-md-3 style-1'>
                            <img src={personal} alt="photo"></img>
                        </div>
                    </div>
                    
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    
                    <div className="row equalize sm-equalize-auto">
                        <div className='col-md-9 bg-light-gray'>
                            <h5 className="sub-heading"><span style={{fontSize:"100%",color:"black"}}>&#9776;</span> Education Loan</h5>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> KYC (Adhar card,Pan card,voter id, 2 photos, electricity bill, bank statement 1 year update)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Income Proof (salary slip of 6 months/Income tax return 3 yrs with P&L balance sheet,form no. 16 of 3 months, Loan statements)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Education Docs (10th and 12th marksheet,Fees Structure,Admit card )</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> List of Document and Loan Statement of running all/ NOC of close.</li>
                        </div>
                        <div className='col-md-3 style-1'>
                            <img src={education} alt="photo"></img>
                        </div>
                    </div>
                    
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    
                    <div className="row equalize sm-equalize-auto">
                        <div className='col-md-9 bg-light-gray'>
                            <h5 className="sub-heading"><span style={{fontSize:"100%",color:"black"}}>&#9776;</span> Business Loan</h5>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> KYC (Adhar card,Pan card,voter id, 2 photos, electricity bill, bank statement 1 year update)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Income Proof (salary slip of 6 months/Income tax return 3 yrs with P&L balance sheet,form no. 16 of 3 months, Loan statements)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Property Paper (Registry, namankan, Property and water tax slip, approved map by Muncipal Coorporation, permission certificate(Anugya praman patra),Chain Registry/Colony Master File</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Gumasta licence, Nagar Nigam Licence</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> List of Document and Loan Statement of running all/ NOC of close.</li>
                        </div>
                        <div className='col-md-3 style-1'>
                            <img src={business} alt="photo"></img>
                        </div>
                    </div>

                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    
                    <div className="row equalize sm-equalize-auto">
                        <div className='col-md-9 bg-light-gray'>
                            <h5 className="sub-heading"><span style={{fontSize:"100%",color:"black"}}>&#9776;</span> Mortgage Loan</h5>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> KYC (Adhar card,Pan card,voter id, 2 photos, electricity bill, bank statement 1 year update)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Income Proof (salary slip of 6 months/Income tax return 3 yrs with P&L balance sheet,form no. 16 of 3 months, Loan statements)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Property Paper (Registry, namankan, Property and water tax slip, approved map by Muncipal Coorporation, permission certificate(Anugya praman patra),Chain Registry/Colony Master File</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> List of Document and Loan Statement of running all/ NOC of close.</li>
                        </div>
                        <div className='col-md-3 style-1'>
                            <img src={mortgage} alt="photo"></img>
                        </div>
                    </div>

                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    
                    <div className="row equalize sm-equalize-auto">
                        <div className='col-md-9 bg-light-gray'>
                            <h5 className="sub-heading"><span style={{fontSize:"100%",color:"black"}}>&#9776;</span> Project Loan</h5>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> KYC (Adhar card,Pan card,voter id, 2 photos, electricity bill, bank statement 1 year update)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Income Proof (salary slip of 6 months/Income tax return 3 yrs with P&L balance sheet,form no. 16 of 3 months, Loan statements)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Property Paper (Registry, namankan, Property and water tax slip, approved map by Muncipal Coorporation, permission certificate(Anugya praman patra),Chain Registry/Colony Master File</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> List of Document and Loan Statement of running all/ NOC of close.</li>
                        </div>
                        <div className='col-md-3 style-1'>
                            <img src={project} alt="photo"></img>
                        </div>
                    </div>
                    
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    
                    <div className="row equalize sm-equalize-auto">
                        <div className='col-md-9 bg-light-gray'>
                            <h5 className="sub-heading"><span style={{fontSize:"100%",color:"black"}}>&#9776;</span> BT Topup Loan</h5>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> KYC (Adhar card,Pan card,voter id, 2 photos, electricity bill, bank statement 1 year update)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Income Proof (salary slip of 6 months/Income tax return 3 yrs with P&L balance sheet,form no. 16 of 3 months, Loan statements)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Property Paper (Registry, namankan, Property and water tax slip, approved map by Muncipal Coorporation, permission certificate(Anugya praman patra),Chain Registry/Colony Master File</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> List of Document and Loan Statement of running all/ NOC of close.</li>
                        </div>
                        <div className='col-md-3 style-1'>
                            <img src={bttop} alt="photo"></img>
                        </div>
                    </div>
                    
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    
                    <div className="row equalize sm-equalize-auto">
                        <div className='col-md-9 bg-light-gray'>
                            <h5 className="sub-heading"><span style={{fontSize:"100%",color:"black"}}>&#9776;</span> Gold Loan</h5>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> KYC (Adhar card,Pan card,voter id, 2 photos, electricity bill, bank statement 1 year update)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> List of Document and Loan Statement of running all/ NOC of close.</li>
                        </div>
                        <div className='col-md-3 style-1'>
                            <img src={gold} alt="photo"></img>
                        </div>
                    </div>
                    
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    
                    <div className="row equalize sm-equalize-auto">
                        <div className='col-md-9 bg-light-gray'>
                            <h5 className="sub-heading"><span style={{fontSize:"100%",color:"black"}}>&#9776;</span> Car Loan</h5>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> KYC (Adhar card,Pan card,voter id, 2 photos, electricity bill, bank statement 1 year update)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Registration card, Insurance Paper, Fitness card, Pollution card </li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Quotation for new buyers</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Income Proof (salary slip of 6 months/Income tax return 3 yrs with P&L balance sheet,form no. 16 of 3 months, Loan statements)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> List of Document and Loan Statement of running all/ NOC of close.</li>
                        </div>
                        <div className='col-md-3 style-1'>
                            <img src={car} alt="photo"></img>
                        </div>
                    </div>
                    
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    
                    <div className="row equalize sm-equalize-auto">
                        <div className='col-md-9 bg-light-gray'>
                            <h5 className="sub-heading"><span style={{fontSize:"100%",color:"black"}}>&#9776;</span> Pension Loan</h5>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> KYC (Adhar card,Pan card,voter id, 2 photos, electricity bill, bank statement 1 year update)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> Income Proof (PPO form, Loan statements)</li>
                            <li><span style={{fontSize:"150%",color:"yellow"}}>&#9733;</span> List of Document and Loan Statement of running all/ NOC of close.</li>
                        </div>
                        <div className='col-md-3 style-1'>
                            <img src={pension} alt="photo"></img>
                        </div>
                    </div>

                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    
                    <div className="row equalize sm-equalize-auto">
                        <h5 className="sub-heading">... and all types of Loan</h5>
                    </div>
                    <div
                                style={{
                                  marginTop: "50px",
                                  color: "#ffc30c",
                                  alignSelf: "flex-start",
                                  fontSize: "large",
                                }}
                                className="content"
                              >
                                * Terms and conditions applied
                              </div>
                    {/* <div className="row equalize sm-equalize-auto">
                        <div className="item">
                            <div className="inner">
                                <div className="themesflat-spacer clearfix" data-desktop={10} data-mobile={10} data-smobile={10} />
                                <h5 className="sub-heading">OUR SERVICES INCLUDE:</h5>
                                <p>We have successfully completed projects in numerous states across the continental United States.
                                    Autora Construction Services has a proven track record of:</p>
                                <div className="themesflat-spacer clearfix" data-desktop={8} data-mobile={8} data-smobile={8} />
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">Interior Design Planning</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">Colour &amp; Finish Consultations</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">Architectural &amp; Custom Lighting</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="themesflat-list has-icon style-1 icon-left size-16 clearfix">
                                    <div className="inner">
                                        <span className="item">
                                            <span className="icon"><i className="fa fa-check-circle" /></span>
                                            <span className="text">Glass &amp; Acrylic Specifications</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                            <div className="themesflat-content-box clearfix" data-margin="0 0 0 11px" data-mobilemargin="0 0 0 0">
                                <div className="inner pd35 bg-ffc">
                                    <h5 className="sub-heading text-white line-height-24 padding-left-7">THIS AWESOME SERVICE BRINGS FULL
                                        INTEREST INFORMATION FOR YOUR PURPOSE</h5>
                                    <div className="themesflat-spacer clearfix" data-desktop={16} data-mobile={16}
                                        data-smobile={16} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                        <Link to="#"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26"><span>Download
                                                .PDF <span className="icon"><i className="fa fa-file-pdf-o" /></span></span></Link>
                                    </div>
                                    <div className="themesflat-spacer clearfix" data-desktop={6} data-mobile={6} data-smobile={6} />
                                    <div className="button-wrap has-icon icon-right size-14">
                                        <Link to="#"
                                            className="themesflat-button bg-white color-333 w100 font-weight-400 no-letter-spacing pd26 "><span>
                                                Download .DOC <span className="icon"><i
                                                        className="fa fa-file-word-o" /></span></span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="themesflat-spacer clearfix" data-desktop={37} data-mobile={35} data-smobile={35} />
                    {/* <div className="row equalize sm-equalize-auto">
                        <h5 className="sub-heading no-letter-spacing">CONTACT US FOR DESIGN-BUILD SERVICES TODAY</h5>
                        <div className="themesflat-spacer clearfix" data-desktop={27} data-mobile={27} data-smobile={27} />
                        <div className="themesflat-tabs style-2 sub-heading-w170 clearfix">
                            <ul className="tab-sub-heading clearfix ">
                                <li className="item-sub-heading active">
                                    <span className="inner">Our focus</span>
                                </li>
                                <li className="item-sub-heading">
                                    <span className="inner">Dedicated</span>
                                </li>
                                <li className="item-sub-heading">
                                    <span className="inner">Committed</span>
                                </li>
                            </ul>
                            <div className="tab-content-wrap clearfix">
                                {
                                    this.state.tabs.map(data =>(
                                        <div className="tab-content" key={data.id}>
                                            <div className="item-content">
                                                <p>{data.text01}</p>
                                                <p>{data.text02}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div> */}
                    <div className="themesflat-spacer clearfix" data-desktop={80} data-mobile={60} data-smobile={60} />
                </div>
            </div>
        );
    }
}

export default SiteContent;